import React from "react"
import { Helmet } from "react-helmet"
import { isMobileSafari, osVersion, isSafari } from "react-device-detect"

import DefaultShareImage from "../../images/share.png"

const SEO = ({
  title,
  description = "",
  lang = "en",
  shareImage = DefaultShareImage,
  pageContext,
}) => (
  <>
    <Helmet
      bodyAttributes={{
        class:
          isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
            ? ""
            : "supports-webp",
      }}
      htmlAttributes={{
        lang,
      }}
    >
      <title>{pageContext?.title || title} | MediaLink</title>
      <meta
        name="description"
        content={pageContext?.description || description}
      />
      <meta
        property="og:title"
        content={pageContext?.title || `${title} | MediaLink`}
      />
      <meta
        property="og:description"
        content={pageContext?.description || description}
      />
      <meta property="og:image" content={pageContext?.image || shareImage} />

      {/* Twitter Specific */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@medialink" />
      <meta name="twitter:title" content={pageContext?.title || title} />
      <meta
        name="twitter:description"
        content={pageContext?.description || description}
      />
      <meta name="twitter:image" content={pageContext?.image || shareImage} />
    </Helmet>
  </>
)

export default SEO
